import React from "react";
import Button from "../../components/Button";
import Layout from "../../components/Layout";
import Section from "../../components/Section";
import { Link } from "gatsby";
import TitleBar from "../../components/TitleBar";
import Headline from "../../components/Headline";
import Ctas from "../../components/Ctas";
import TableOfContents from "../../components/TableOfContents";
import TableOfContentsItem from "../../components/TableOfContentsItem";
import SupportLink from "../../components/SupportLink";
import Feature from "../../components/Feature";
import WorksWith from "../../components/WorksWith";
import Issue from "../../components/Issue";
import Callout from "../../components/Callout";
import { DiscussionWarehouse } from "../../components/Icons";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import Screenshot from '../../images/discussion-warehouse/screenshot.png'
import FooterCta from "../../components/FooterCta";

const ToolkitQna = () => {
  return (
    <Layout title="Manage shared knowledge threads in a single place" description="A repository to manage shared knowledge threads from Slack, Q&A.js, and other sources">
      <TitleBar title="Discussion Warehouse" toolkit icon={<DiscussionWarehouse />}>
        <div className="hidden sm:flex">
          <Button url="https://squeak.cloud/signup">
              Try Squeak! Cloud
          </Button>
        </div>
      </TitleBar>
      
      <article className="">
        <Section id="overview">
          <Headline
            h1="Keep valuable product discussions in a single format"
            subtitle="A repository to manage shared knowledge threads from Slack, Q&amp;A.js, and other sources"
          />

          <p className="mb-8">Use with <Link href="/toolkit/qna.js">Q&amp;A.js</Link> to display threads on specific pages of your docs or website.</p>

          <Ctas>
            <Button url="https://squeak.cloud/signup">Try Squeak! Cloud</Button>

            {/*
            <div className="relative">
              <Button
                url="#demo"
                type="secondary"
                className="w-full"
              >
                Demo
              </Button>
              <TrySqueak className="absolute top-[140%] sm:top-[105%] pt-1 left-[35%] sm:left-[75%] rotate-3 sm:-rotate-6" />
            </div>
            */}
          </Ctas>
        </Section>
      
        <TableOfContents>
            <TableOfContentsItem name="Screenshot" url="#screenshots" />
            <TableOfContentsItem name="Features" url="#features" />
            <TableOfContentsItem name="How it works" url="#how-it-works" />
            <TableOfContentsItem name="Demo" url="#demo" />
            <SupportLink name="Questions?" url="/questions" />
        </TableOfContents>

        <Section id="screenshots" className="my-0">
          <Zoom>
            <img src={Screenshot} className="max-w-3xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl -mx-6 w-[calc(100%+3rem)] xl:-mx-10 xl:w-[calc(100%+5rem)] 2xl:-mx-12 2xl:w-[calc(100%+6rem)] transition-all duration-300" alt="Import Slack Threads by Squeak!"/>
          </Zoom>
        </Section>

        <Section id="features">
            <h3>Features</h3>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Available now</h4>
            <ul className="text-base mb-8">
              <Feature name="Set an SEO-friendly subject to summarize the thread" />
              <Feature name="Publish threads to specific pages of your website with Q&A.js" />
              <Feature name="Mark a response as the thread’s solution" />
              <Feature name="Close a thread to new responses" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">Toolkit integrations</h4>
            <ul className="text-base mb-8">
              <WorksWith toolkitItem="Q&A.js" to="Choose a URL where the thread should be displayed"  url="" />
            </ul>

            <h4 className="text-black text-opacity-60 text-lg pb-2">On the roadmap</h4>
            <ul className="text-base mb-8">
              <Issue name="Edit messages for brevity or to remove sensitive info" url="https://github.com/PostHog/squeak/issues/29" />
              <Issue name="Import threads from CSV" url="https://github.com/PostHog/squeak/issues/60" />
              <Issue name="Export threads to CSV" url="https://github.com/PostHog/squeak/issues/149" />
              <Issue name="API for accessing threads" url="https://github.com/PostHog/squeak/issues/150" />
            </ul>

            <Callout>
              <p>We have no plans to charge for this product.</p>
            </Callout>

        </Section>

        <FooterCta 
          title="Try Squeak!"
          description="It's not hard. It's just a few clicks."
        />
      </article>
    </Layout>
  );
};

export default ToolkitQna;
